<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="deUserPage" :fields="fields" :search-model="searchModel"
                :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="generatorNo" style="width: 120px">
          <el-input v-model="searchModel.generatorNo" clearable placeholder="发电户号" style="width: 120px;"></el-input>
        </el-form-item>
        <el-form-item label="" prop="state" style="width: 120px">
          <el-select v-model="searchModel.state" clearable placeholder="用户状态" style="width: 120px;">
            <el-option v-for="(value, key) in stateMap" :key="key" :label="value" :value="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="developersId">
          <el-select v-model="searchModel.developersId" filterable clearable
                     style="width: 150px" placeholder="开发商名称">
            <el-option v-for="item in developer_options" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <el-button @click="handleAdd" size="mini" type="primary">新建</el-button>
      </template>
    </page-table>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {deUserPage, developerAll} from "@/api/common"

const stateMap = {
  '00': '未授权',
  '01': '预授权',
  '02': '已授权',
  '03': '授权结束',
  '04': '失败',
  '05': '授权暂停',
  '06': '授权中止',
  '07': '待上传',
  '08': '已上传',
  '09': '上传失败',
  '10': '无效'
}

export default {
  name: "DeUsers",
  data() {
    return {
      searchModel: {
        generatorNo: '',
        state: '',
        developersId: '',
      },
      deUserPage: deUserPage,
      fields: [
        {
          label: '开发商名称',width: 180,
          render({developers}, h) {
            return h('span',developers ? developers.name : '')
          }
        },
        {label: '农户姓名', key: 'name',width: 110},
        {label: '身份证号', key: 'idNo',width:180},
        {label: '发电户号', key: 'generatorNo',width:110},
        {label: '省份', key: 'province',width: 90},
        {
          label: '用户状态', width: 100,
          render({state}, h) {
            return h('div', stateMap[state])
          }
        },
        {
          label: "签约状态", width: 100,
          render({state1}, h) {
            return h('div', ['未签约', '已签约', '已解约'][state1])
          }
        },
        {
          label: "划转状态",width: 100,
          render({state2}, h) {
            return h('div', {'01': '未生效', '02': '已生效'}[state2])
          }
        },
        {label: '上传时间', key: 'uploadTime',width: 180},
        {label: '并网时间', key: 'signTime',width: 180},
        {label: '授权生效时间', key: 'authorisationTime',width: 180},
      ],
      actions: [
        {action: 'info', label: '查看详情', type: 'primary'},
        {action: 'edit', label: '编辑', type: 'primary'},
      ],
      stateMap: stateMap,
      developer_options: [],
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {
    this.getDeveloperOptions()
  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    getDeveloperOptions() {
      let that = this
      developerAll().then(res => {
        that.developer_options = res
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    infoAction(item) {
      this.$router.push({path: `/admin/de-user/info/${item.id}`})
    },
    editAction(item) {
      this.$router.push({path: `/admin/de-user/edit/${item.id}`})
    },
    handleAdd() {
      this.$router.push({path: '/admin/de-user/add'})
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
